import { useEffect, useState, Fragment } from "react";
import OttHeroSlider from "../../Components/slider/OttHeroSlider";
import HomeHeroSlider from "../../Components/slider/HomeHeroSlider";
import TvShowHeroSlider from "../../Components/slider/TvShowHeroSlider";


// sections
import ContinueWatching from "../../Components/sections/ContinueWatching";
import LandscapeCard from "../../Components/sections/LandscapeCard";
import TopTenMoviesToWatch from "../../Components/sections/TopTenMoviesToWatch";
import VerticalSectionSlider from "../../Components/slider/VerticalSectionSlider";
import PopularMovies from "../../Components/sections/PopularMovies";
import TabSlider from "../../Components/sections/TabSlider";
// //static data
import { useSelector } from "react-redux";

const ContentSections = (({ data, fontClass }) => {
    const [contentRow, setContentRow] = useState();
    const projectInfo = useSelector((state) => state?.projectInfo?.value);
    const renderContentRow = () => {
        if (data.type === "FEATURED") {
            if (projectInfo?.projectConfig?.config?.TEMPLATE === "TEMPLATE_5") {
                return <OttHeroSlider fontClass={fontClass} data={data} />
            } else if (projectInfo?.projectConfig?.config?.TEMPLATE === "TEMPLATE_6") {
                return <HomeHeroSlider fontClass={fontClass} data={data} />
            } else if (projectInfo?.projectConfig?.config?.TEMPLATE === "TEMPLATE_7") {
                return <TvShowHeroSlider fontClass={fontClass} data={data} />
            } else {
                return <OttHeroSlider fontClass={fontClass} data={data} />
            }

        } else if (data.type === "TOP_TRENDING") {
            return <TopTenMoviesToWatch fontClass={fontClass} data={data} />
        } else if (data.type === "CONTINUE_WATCHING") {
            return <ContinueWatching fontClass={fontClass} data={data} />
        } else if (data.type === "FEATURED2") {
            return <VerticalSectionSlider sliderData={data} fontClass={fontClass} data={data} />
        } else if (data.type === "TOP_FEATURED") {
            return <TabSlider sliderData={data} fontClass={fontClass} data={data} />
        } else if (data.type !== "LIVE_GUIDE") {
            // Return something else if data.type is not "FEATURED"
            return <Fragment>
                {data?.thumbnail_orientation === "PORTRAIT" || projectInfo?.projectConfig?.config?.THUMBNAIL_ORIENTATION === "PORTRAIT" ?
                    <PopularMovies fontClass={fontClass} data={data} type={data.type}/>
                :
                    <LandscapeCard fontClass={fontClass} data={data} />
                }
                

                {/* <TopTenMoviesToWatch />
                <OnlyOnStreamit />
                <VerticalSectionSlider sliderData={ottVerticleLatestMovies} />
                <YourFavouritePersonality />
                <PopularMovies />
                <TabSlider />
                <GenreSlider />
                <RecommendedForYou />
                <TopPicsForYou /> */}
            </Fragment>

        }
    };

    useEffect(() => {
        setContentRow(renderContentRow());
    }, [data, fontClass]);

    return <Fragment>{contentRow}</Fragment>
});

ContentSections.displayName = "ContentSections";
export default ContentSections;