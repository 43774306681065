import React, { useRef, useState, useEffect, useCallback, useMemo } from "react";
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import { onVideoPlayFunction } from "../../utils/utils";
import ShortComment from './ShortsComments/ShortComments'
import { likeHandler } from "./service";
import { Pause as PauseIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material';

//stable
const Videos = ({
  id, src, like, comment, projectInfo,
  isActive, isVisible, title, desc,
  appInfo, shareUrl, vanityUrl,
  shouldCloseCommentModal, setShouldCloseCommentModal,
  onVideoEnded,
  liked_flag,
  poster
}) => {
  const [playing, setPlaying] = useState(false);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const playTimeoutRef = useRef(null);
  const [liked, setLiked] = useState(false);
  const [likeCount, setLikeCount] = useState(like);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [pauseIconVisible, setPauseIconVisible] = useState(false);
  const [playIconVisible, setPlayIconVisible] = useState(false);
  const [commentCount, setCommentCount] = useState(comment);
  const [likeColor, setLikeColor] = useState(liked_flag);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const navigate = useNavigate();
  var videoStarted = false;
  let videoPlaying = false;
  let videoDetails = {
    video_id: id,
    video_title: title
  }
  const playerOptions = useMemo(() => ({
    autoplay: true,
    controls: false,
    responsive: true,
    loop: true,
    fluid: true,
    preload: 'auto',
    techOrder: ['html5'],
    sources: [{ src, type: 'application/x-mpegURL' }],
  }), [src]);

  const updateShortsVideoAnalytics = async (event, time) => {
    const updateResponse = await onVideoPlayFunction(
      appInfo,
      videoDetails,
      event,
      time,
      null,
      null,
      null
    );
  };
  useEffect(() => {
    if (isVisible) {
      if (!playerRef.current) {
        const player = videojs(videoRef.current, playerOptions);

        playerRef.current = player;

        player.on('play', () => {
          console.log('play');
          setPlaying(true);
          setShowIcons(true);
          clearTimeout(playTimeoutRef.current);

          playTimeoutRef.current = setTimeout(() => setShowIcons(false), 1000);
        });

        player.on('pause', () => {
          console.log('paused');
          setPlaying(false);
          setShowIcons(true);

          clearTimeout(playTimeoutRef.current);
          playTimeoutRef.current = setTimeout(() => setShowIcons(false), 1000);
        });

        player.on('ended', () => {
          console.log('ended');
          if (!isCommentModalOpen) {
            if (onVideoEnded) onVideoEnded();
          }
          // Call the onVideoEnded callback

        });
        player.on("dispose", () => {
          videoPlaying = false;
          videoStarted = false;
          player.pause();
          console.log("disposed");
        });
      }
    } else {
      if (playerRef.current) {
        playerRef.current.pause();
        playerRef.current.currentTime(0);
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
      if (playTimeoutRef.current) {
        clearTimeout(playTimeoutRef.current);
      }
    };
  }, [playerOptions, isVisible]);
  useEffect(() => {
    if (playerRef.current) {
      if (isShareModalOpen) {
        playerRef.current.pause();
      } else if (isActive) {
        playerRef.current.play();
      }
    }
  }, [isShareModalOpen, isActive]);
  const handleVideoPress = () => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pause();
      } else {
        playerRef.current.play();
      }
    }
  };


  useEffect(() => {
    if (playerRef.current) {
      if (isActive) {

        let event = 'POP12'
        updateShortsVideoAnalytics(event, playerRef.current.currentTime())


        playerRef.current.on('waiting', (e) => {
          handleBufferSeekOverHoleError(playerRef.current)
          console.error('Video.js waiting log:', e);
        });

        playerRef.current.on('ended', () => {
          console.log('ended');
          if (!isCommentModalOpen) {
            if (onVideoEnded) onVideoEnded();
          }


        });
        if (playTimeoutRef.current) {
          clearTimeout(playTimeoutRef.current);
        }
        playerRef.current.muted(false);
        playTimeoutRef.current = setTimeout(() => {
          if (playerRef.current && !playerRef.current.isDisposed()) {
            playerRef.current.play().catch(error => {
              console.log("Play prevented:", error);
            });
          }
        }, 50);
      } else {

        if (playTimeoutRef.current) {
          clearTimeout(playTimeoutRef.current);
        }
        playerRef.current.pause();
        // playerRef.current.muted(true);
      }
    }

    return () => {
      if (playTimeoutRef.current) {
        clearTimeout(playTimeoutRef.current);
      }
    };
  }, [isActive]);
  useEffect(() => {
    setCommentCount(commentCount)
  }, [commentCount]);


  useEffect(() => {
    const handleVideoPress = () => {
      if (playerRef.current) {
        if (playing) {
          playerRef.current.pause();
          setPauseIconVisible(true)
          setPlayIconVisible(false)
        } else {
          playerRef.current.play();
          setPauseIconVisible(false)
          setPlayIconVisible(true)
        }
      }
    };

    const videoElement = videoRef.current;

    const videoEnd = () => {
      if (!isCommentModalOpen) {
        if (onVideoEnded) onVideoEnded();
      }
    };
    if (videoElement) {
      videoElement.addEventListener('click', handleVideoPress);
      videoElement.addEventListener('touchstart', handleVideoPress);

      videoElement.addEventListener('ended', () => {
        console.log('Video is ended');
        // Show loading spinner
        videoEnd()
      });


    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('click', handleVideoPress);
        videoElement.removeEventListener('touchstart', handleVideoPress);

        videoElement.removeEventListener('ended', videoEnd);
      }
    };
  }, [playing]);

  useEffect(() => {
    if (shouldCloseCommentModal) {
      setCommentModalOpen(false);
      setShouldCloseCommentModal(false);
    }
  }, [shouldCloseCommentModal, setShouldCloseCommentModal]);

  useEffect(() => {

    if (liked_flag === 'blue') {
      setLiked(true);
      setLikeColor('blue');

    } else {
      setLiked(false);
      setLikeColor('#fff');

    }
  }, [liked_flag]);

  const handleBufferSeekOverHoleError = (player) => {
    const currentTime = player.currentTime();
    player.currentTime(currentTime + 3);
  };

  const handleLike = useCallback(async () => {
    try {
      let newLikedStatus;
      if (liked_flag === 'blue') {


        if (likeColor === '#fff') {
          newLikedStatus = true;
          setLikeColor('blue');
          setLikeCount(prevCount => prevCount + 1);
        } else {
          newLikedStatus = false;
          setLikeColor('#fff');
          setLikeCount(prevCount => prevCount - 1);
        }

      } else {
        newLikedStatus = !liked;
        setLikeColor(newLikedStatus ? 'blue' : '#fff');
        setLikeCount(prevCount => newLikedStatus ? prevCount + 1 : prevCount - 1);
      }
      setLiked(newLikedStatus);



      await likeHandler(id, newLikedStatus, appInfo);
    } catch (error) {
      console.error('Error updating like status:', error);
    }
  }, [liked, id, appInfo]);


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };
  const openCommentModal = useCallback(() => setCommentModalOpen(true), []);
  const closeCommentModal = useCallback(() => setCommentModalOpen(false), []);
  const toggleDescription = useCallback(() => setIsExpanded(prev => !prev), []);
  const openShareModal = useCallback(() => setShareModalOpen(true), []);
  const closeShareModal = useCallback(() => setShareModalOpen(false), []);

  const shareOptions = useMemo(() => (
    <>
      <FacebookShareButton url={shareUrl} className="share-button facebook">
        <i className="fa-brands fa-facebook-f"></i>
      </FacebookShareButton>
      <TwitterShareButton url={shareUrl} className="share-button twitter">
        <i className="fa-brands fa-twitter"></i>
      </TwitterShareButton>
    </>
  ), [shareUrl]);

  return (

    <div className="video-container" id={id} data-id={id} data-vanity-url={vanityUrl}>
      {
        src ?
          <div className="video-shorts">
            <div data-vjs-player>
              <video ref={videoRef} data-src={src} className="video-js" poster={poster} onClick={handleVideoPress} loop loading="lazy" />
              <div className={`video-overlay ${showIcons ? '' : 'hidden'}`}>

                {
                  pauseIconVisible && <PauseIcon className="pause-icon" />

                }
                {
                  playIconVisible && <PlayArrowIcon className="play-icon" />

                }

              </div>
            </div>
            <div className="shortsContainer">
              <div className="shortsVideoSideIcons">
                <div className="shortsVideoSideIcon" onClick={handleLike}>
                  <ThumbUpIcon style={{ color: likeColor }} /><span style={{ color: `#fff` }}>{likeCount}</span>
                </div>
                <div className="shortsVideoSideIcon" onClick={openCommentModal}>
                  <InsertCommentIcon />
                  <span style={{ color: "#fff" }}>{commentCount > 0 ? commentCount : comment}</span>
                </div>
                <div className="shortsVideoSideIcon" onClick={openShareModal}>
                  <ShareIcon />
                </div>
              </div>
              <div className="shortsBottom">
                <div className="shortsDesc">
                  <p>{title}</p>
                </div>
                <div className="shortDetails">
                  <p style={{ whiteSpace: isExpanded ? 'normal' : 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {desc}
                  </p>
                  {desc?.length > 100 && (
                    <span className="show-more-button" onClick={toggleDescription}>
                      {isExpanded ? 'Show Less' : 'Show More'}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {isCommentModalOpen && (
              <div className="comment-modal">
                <ShortComment
                  videoId={id}
                  projectInfo={projectInfo}
                  isFromShorts={true}
                  setCommentModalOpen={setCommentModalOpen}
                  setCommentCount={setCommentCount}
                />
              </div>
            )}
            {isShareModalOpen && (
              <div className="share-modal-overlay" onClick={closeShareModal}>
                <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>
                  <button className="share-modal-close" onClick={closeShareModal}>×</button>
                  <h3>Share</h3>
                  <div className="share-options">
                    {shareOptions}
                  </div>
                  <div className="share-url-container">
                    <input type="text" value={shareUrl} readOnly className="share-url-input" />
                    <button onClick={handleCopy} className="copy-button">
                      {isCopied ? 'Copied' : 'Copy'}
                    </button>

                  </div>
                </div>
              </div>
            )}
          </div> : null

      }

    </div>
  );
};

export default Videos;
