import { useEffect, useState, Fragment, memo } from "react";

//componets
import SectionSlider from "../slider/SectionSlider";
import CardStyle from "../cards/CardStyle";


const PopularMovies = memo(({ data, type }) => {
  const [title, setTitle] = useState("");
  const [shows, setShows] = useState([]);
  let itemLink
  useEffect(() => {
    setShows(data?.shows)
    setTitle(data?.category_name)
  }, [data]);
  return (
    <Fragment>
      <div>
      <SectionSlider
        title={title}
        list={shows}
        data={data}
        className="popular-movies-block streamit-block"
        margin={true}
        shorts={type === "SHORTS"}
      >
        {(data) => {
          if (data.type === "UPCOMING_EVENT" || data.type === "LIVE_EVENT" ) {
            itemLink = `/event/${data?.event_id}`
          } else if (data.type === "PODCAST") {
            itemLink = `/podcast/${data.podcast_id}`
          } else if (data.type === "SHOW") {
            itemLink = {
              pathname: `/show-details/${data?.vanity_url}`,
              state: { data: data }
          };
          } else if (data.type === "NEWS") {
            itemLink = "/news"
          }else if (data.type === "SHORTS") {           
            itemLink = `shorts`
          }
          return (
            <CardStyle
              image={data?.logo || data?.thumbnail}
              title={data?.show_name}
              movieTime={data?.duration_text}
              watchlistLink="/playlist"
              link={itemLink}
              data={data}
              share={true}
              vanity_url={data?.vanity_url}
              watchlistStatus={data?.watchlist_flag}
              showWatchList={data.type === "SHORTS" ? false:true}
            />
          )
        }}
      </SectionSlider>
      </div>
    </Fragment>
  );
})

PopularMovies.displayName = 'PopularMovies';
export default PopularMovies;
